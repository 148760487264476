// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* Octuple Colors: when used, please copy from octuples... */
  /* https://github.com/EightfoldAI/octuple/blob/main/src/styles/themes/_default-theme.scss */
  --grey-color-10: #f6f7f8;
  --grey-color-60: #69717f;
  --grey-color-70: #4f5666;
  --grey-color-90: #1a212e;
  --grey-color-200: #ebedf1;
  --grey-gradient-start-color-10: #ffffff;
  --grey-gradient-middle-color-80: #343c4c;
  --grey-gradient-end-color-10: #d9dce1;
  --green-color-50: #51ab93;
  --green-color-90: #0e2e26;
  --green-color-400: #3c8c75;
  --blue-color-20: #bce4ff;
  --blue-color-60: #2c8cc9;
  --blue-color-70: #146da6;
  --orange-color-20: #ffe3b0;
  --red-color-10: #ffefef;
  --red-color-80: #6c2222;
  --navbar-text-color: #002136;
  --font-stack-full: "Avenir Book", sans-serif;
  /* non-octuple colors */
  --act-dark: #993838;
  --act-medium: purple; /* obviously wrong */
  --act-light: #ffefef;
  --act-text: #993838;
  --watch-dark: #b7873b;
  --watch-medium: #ffe3b0;
  --watch-light: #ffffeb;
  --watch-text: #9e5f00;
  --maintain-light: #f0fefa;
  --maintain-medium: #b9f4e4;
  --maintain-dark: #3c8c75;
  --maintain-text: #2b715f;
  --accordion-border: 0;
  --background-color: #ffffff;
}

.card-tooltip {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,4DAAA;EACA,2FAAA;EACA,wBAAA;EACA,wBAAA;EACA,wBAAA;EACA,wBAAA;EACA,yBAAA;EACA,uCAAA;EACA,wCAAA;EACA,qCAAA;EACA,yBAAA;EACA,yBAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EACA,wBAAA;EACA,0BAAA;EACA,uBAAA;EACA,uBAAA;EACA,4BAAA;EACA,4CAAA;EAEA,uBAAA;EACA,mBAAA;EACA,oBAAA,EAAA,oBAAA;EACA,oBAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;EACA,sBAAA;EACA,qBAAA;EACA,yBAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EAEA,qBAAA;EACA,2BAAA;AADF;;AAIA;EACE,mBAAA;AADF","sourcesContent":[":root {\n  /* Octuple Colors: when used, please copy from octuples... */\n  /* https://github.com/EightfoldAI/octuple/blob/main/src/styles/themes/_default-theme.scss */\n  --grey-color-10: #f6f7f8;\n  --grey-color-60: #69717f;\n  --grey-color-70: #4f5666;\n  --grey-color-90: #1a212e;\n  --grey-color-200: #ebedf1;\n  --grey-gradient-start-color-10: #ffffff;\n  --grey-gradient-middle-color-80: #343c4c;\n  --grey-gradient-end-color-10: #d9dce1;\n  --green-color-50: #51ab93;\n  --green-color-90: #0e2e26;\n  --green-color-400: #3c8c75;\n  --blue-color-20: #bce4ff;\n  --blue-color-60: #2c8cc9;\n  --blue-color-70: #146da6;\n  --orange-color-20: #ffe3b0;\n  --red-color-10: #ffefef;\n  --red-color-80: #6c2222;\n  --navbar-text-color: #002136;\n  --font-stack-full: \"Avenir Book\", sans-serif;\n\n  /* non-octuple colors */\n  --act-dark: #993838;\n  --act-medium: purple; /* obviously wrong */\n  --act-light: #ffefef;\n  --act-text: #993838;\n  --watch-dark: #b7873b;\n  --watch-medium: #ffe3b0;\n  --watch-light: #ffffeb;\n  --watch-text: #9e5f00;\n  --maintain-light: #f0fefa;\n  --maintain-medium: #b9f4e4;\n  --maintain-dark: #3c8c75;\n  --maintain-text: #2b715f;\n\n  --accordion-border: 0;\n  --background-color: #ffffff;\n}\n\n.card-tooltip {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
