var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconName, Stack } from "@eightfold.ai/octuple";
import React, { useMemo, useRef, useState, } from "react";
import { useParams } from "react-router-dom";
import { getDisplayableTimestamp } from "../../common/datetime";
import BackToButton from "../../components/BackToButton";
import { ControlledOverviewTabs } from "../../components/OveriewTabs/OverviewTabs";
import SecondaryMainHeading from "../../components/SecondMainHeading/SecondMainHeading";
import SpinnerAndErrorer from "../../components/SpinnerAndErrorer";
import { getRoute } from "../../routes/getAllRoutes";
import { SkillGroup, updateSkill, useGetClientPlatformQuery, useGetSkillsCategoryQuery, useSuccessProfileDownloadReport, } from "../../store/api/core";
import { hasPermissionExist } from "../../store/api/permissions";
import { SuccessProfilesFunctionalImpact, SuccessProfilesOrganizationalImpact, SuccessProfilesCapabilities, SuccessProfilesPotential, } from "../../components/SuccessProfilesSkillGroup/SuccessProfilesSkillGroup";
import { useImpactDataSource, useCapabilitiesPotentialDataSource, CriticalSkillTag, } from "../../components/SuccessProfilesSkillGroup/SuccessProfileDataSource";
import styles from "./SuccessProfileRole.module.scss";
import { LookupField } from "../../components/LookupField/LookupField";
import Dropdown from "../../components/Dropdown";
import Button, { ButtonVariant as IntelligenceButtonVariant, } from "../../components/Button/Button";
import { OutlinedButton } from "../../components/OutlinedButton/OutlinedButton";
import Dialog from "../../components/Dialog";
import { createPortal } from "react-dom";
import { ProgressStepper, } from "../../components/SuccessProfilesSkillGroup/Skill/Skill";
import { capabilitiesPotential, functionalOrganizational, IdEchoingEditableSlider, } from "../../components/EditableSlider/EditableSlider";
import WarningIcon from "@mui/icons-material/Warning";
var RevertIcon = "M20 13.5C20 17.09 17.09 20 13.5 20H6V18H13.5C16 18 18 16 18 13.5S16 9 13.5 9H7.83L10.91 12.09L9.5 13.5L4 8L9.5 2.5L10.92 3.91L7.83 7H13.5C17.09 7 20 9.91 20 13.5Z";
var OverviewRoleSummary = function (_a) {
    var description = _a.description;
    return (_jsx("div", __assign({ className: styles.overviewRoleSummary }, { children: _jsxs(Stack, __assign({ direction: "vertical", gap: "s" }, { children: [_jsx("div", __assign({ className: styles.overviewRoleSummaryTitle }, { children: "Role Summary" })), _jsx("div", __assign({ className: styles.overviewRoleSummaryDescription }, { children: description ? description : "N/A" }))] })) })));
};
var SummaryCardEntry = function (_a) {
    var title = _a.title, value = _a.value;
    return (_jsxs(Stack, __assign({ direction: "vertical", gap: "xxxs" }, { children: [_jsx("div", __assign({ className: styles.summaryTitle }, { children: title })), _jsx("div", __assign({ className: styles.summaryValue }, { children: value }))] })));
};
var SummaryCard = function (_a) {
    var children = _a.children;
    return _jsx("div", __assign({ className: styles.overviewSummaryCard }, { children: children }));
};
var BreakdownCard = function (_a) {
    var title = _a.title, breakdownNames = _a.breakdownNames;
    return (_jsx(SummaryCard, { children: _jsxs("div", __assign({ "data-testid": "breakdown-".concat(title) }, { children: [_jsx("div", __assign({ className: styles.breakdownTitle }, { children: title })), _jsx("div", { className: styles.breakdownDivider }), _jsx("div", { children: breakdownNames.length > 0 ? (breakdownNames.map(function (name, index) { return (_jsx("div", __assign({ className: styles.breakdownBodyItem }, { children: name }), index)); })) : (_jsx("div", __assign({ className: styles.breakdownBodyItem }, { children: "No critical factors" }))) })] })) }));
};
var getCriticalSkillNames = function (dataSource) {
    return dataSource.skills.reduce(function (acc, skill) {
        skill.skills.forEach(function (skill) {
            if (skill.tag && skill.tag === CriticalSkillTag) {
                acc.push(skill.name);
            }
        });
        return acc;
    }, []);
};
var CriticalForSuccess = function (props) {
    var functionalData = getCriticalSkillNames(useImpactDataSource(props.clientId, props.roleProfileId, SkillGroup.Functional));
    var organizationalData = getCriticalSkillNames(useImpactDataSource(props.clientId, props.roleProfileId, SkillGroup.Organizational));
    var capabilitiesData = getCriticalSkillNames(useCapabilitiesPotentialDataSource(props.clientId, props.roleProfileId, "Capabilities"));
    var potentialData = getCriticalSkillNames(useCapabilitiesPotentialDataSource(props.clientId, props.roleProfileId, "Potential"));
    if (!functionalData.length &&
        !organizationalData.length &&
        !capabilitiesData.length &&
        !potentialData.length) {
        // If there are no critical factors, then hide this section
        return _jsx("div", {});
    }
    return (_jsxs("div", __assign({ className: styles.breakdown, "data-testid": "critical-for-success" }, { children: [_jsx("div", __assign({ className: styles.breakdownCardTitle }, { children: "Critical for Success" })), _jsxs("div", __assign({ className: styles.breakdownCardContainer }, { children: [_jsxs("div", __assign({ className: styles.breakdownCardRow }, { children: [_jsx(BreakdownCard, { title: "Functional Impact", breakdownNames: functionalData }), _jsx(BreakdownCard, { title: "Organizational Impact", breakdownNames: organizationalData })] })), _jsxs("div", __assign({ className: styles.breakdownCardRow }, { children: [_jsx(BreakdownCard, { title: "Capabilities", breakdownNames: capabilitiesData }), _jsx(BreakdownCard, { title: "Potential", breakdownNames: potentialData })] }))] }))] })));
};
var GuidanceDialog = function (_a) {
    var onClose = _a.onClose, onClick = _a.onClick;
    return (_jsx(Dialog, { visible: true, onClose: onClose, buttons: {
            okButtonProps: {
                text: "Got it",
                onClick: onClick,
            },
        }, header: "Guidance for how to edit target experience levels", body: [
            "The experience levels targets have already been validated",
            "during a rigorous process knowledge. You may adjust them if",
            "you are looking for someone with more or less experience in",
            "a given area. Please note that in general, more senior",
            "leaders should have higher experience levels needed than the",
            "leaders below them, so you should aim to set experience levels",
            "needed that increase across leadership levels.",
        ].join(" ") }));
};
var exitDialogTexts = {
    ok: "Exit without saving",
    cancel: "Continue editing",
    heading: "Are you sure you want to exit without saving?",
    description: [
        "You have unsaved changes. If you leave now, any changes",
        "made will be lost.",
    ].join(" "),
};
var saveDialogTexts = {
    ok: "Confirm and save",
    cancel: "Continue Editing",
    heading: "Please confirm that you want to make these changes",
    description: [
        "Changing experience levels not only impacts what",
        "displays for a success profile. It also impact the",
        "results for any future assessments about a leader's",
        "experience.",
    ].join(" "),
};
var ConfirmationDialog = function (_a) {
    var _b = _a.texts, ok = _b.ok, cancel = _b.cancel, heading = _b.heading, description = _b.description, onClose = _a.onClose, onClick = _a.onClick;
    return (_jsx(Dialog, { visible: true, onClose: onClose, buttons: {
            okButtonProps: {
                text: ok,
                onClick: onClick,
            },
            cancelButtonProps: {
                text: cancel,
                onClick: onClose,
            },
        }, header: _jsxs("div", __assign({ className: styles.confirmationDialogHeader }, { children: [_jsx(WarningIcon, { className: styles.warningIcon }), _jsx("div", { children: heading })] })), body: description }));
};
var SuccessProfileOverview = function (_a) {
    var _b, _c;
    var clientId = _a.clientId, roleProfileId = _a.roleProfileId;
    var response = useGetSkillsCategoryQuery({ clientId: clientId, roleProfileId: roleProfileId });
    var data = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0];
    if (!data) {
        return _jsx(SpinnerAndErrorer, { conditions: [response] });
    }
    var _d = data.roleProfile, description = _d.description, name = _d.name, functionCode = _d.functionCode, subFunctionCode = _d.subFunctionCode, levelCode = _d.levelCode;
    return (_jsxs("div", __assign({ className: styles.overviewTab }, { children: [_jsxs(SummaryCard, { children: [_jsxs(Stack, __assign({ direction: "vertical", gap: "m" }, { children: [_jsx(SummaryCardEntry, { title: "Role Name", value: name }), _jsx(SummaryCardEntry, { title: "Function", value: _jsx(LookupField, { clientId: clientId, code: functionCode }) }), _jsx(SummaryCardEntry, { title: "Sub-Function", value: _jsx(LookupField, { clientId: clientId, code: subFunctionCode }) }), _jsx(SummaryCardEntry, { title: "Level", value: _jsx(LookupField, { clientId: clientId, code: levelCode }) })] })), " "] }), _jsxs("div", __assign({ className: styles.overviewTabBody }, { children: [_jsx(OverviewRoleSummary, { description: description }), clientId && (_jsx(CriticalForSuccess, { clientId: clientId || "", roleProfileId: roleProfileId }))] }))] })));
};
var BannerTitle = function (_a) {
    var _b, _c;
    var roleName = _a.roleName, clientId = _a.clientId, lastUpdatedTs = _a.lastUpdatedTs;
    var name = "Heidrick Library";
    if (clientId) {
        var response = useGetClientPlatformQuery({ clientId: clientId });
        name = ((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0].name) || "";
    }
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: styles.secondaryTitle }, { children: roleName })), _jsxs(Stack, __assign({ direction: "horizontal", gap: "xxs" }, { children: [_jsx("div", __assign({ className: styles.secondaryTitleClientName }, { children: "".concat(name ? "".concat(name, " ") : "", "Success Profile") })), _jsx("div", __assign({ className: styles.secondaryTitleTimestamp }, { children: "Last Updated: ".concat(getDisplayableTimestamp(lastUpdatedTs)) }))] }))] }));
};
var getDiscreteEditableSlider = function (onChange) {
    return function (_a) {
        var id = _a.id, currentStep = _a.currentStep;
        return (_jsx(IdEchoingEditableSlider, { initial: currentStep, calculated: currentStep, onChange: onChange, id: id, valueDescription: functionalOrganizational }));
    };
};
var getFloatingEditableSlider = function (onChange) {
    return function (_a) {
        var id = _a.id, currentStep = _a.currentStep;
        return (_jsx(IdEchoingEditableSlider, { initial: currentStep, calculated: currentStep, onChange: onChange, id: id, valueDescription: capabilitiesPotential }));
    };
};
var UneditableProgressStepperFactories = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    functional: function (editListener) { return ProgressStepper; },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    organizational: function (editListener) { return ProgressStepper; },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    capabilities: function (editListener) { return ProgressStepper; },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    potential: function (editListener) { return ProgressStepper; },
};
var EditableProgressStepperFactories = {
    functional: function (editListener) { return getDiscreteEditableSlider(editListener); },
    organizational: function (editListener) { return getDiscreteEditableSlider(editListener); },
    capabilities: function (editListener) { return getFloatingEditableSlider(editListener); },
    potential: function (editListener) { return getFloatingEditableSlider(editListener); },
};
export var SuccessProfileRoleImplementation = function (_a) {
    var _b, _c;
    var clientId = _a.clientId, roleProfileId = _a.roleProfileId, edits = _a.edits;
    var getEditsClient = function () { return clientId || "Master"; };
    var _d = useState(Math.random()), revertKey = _d[0], setRevertKey = _d[1];
    var _e = useState(Object.values((edits === null || edits === void 0 ? void 0 : edits.current) || {}).some(function (changes) {
        return Object.values(changes).some(function (value) { return !!value; });
    })), hasEdits = _e[0], setHasEdits = _e[1];
    var makeEdit = function (id, value) {
        if (!edits.current) {
            edits.current = {};
        }
        if (!edits.current[getEditsClient()]) {
            edits.current[getEditsClient()] = {};
        }
        setHasEdits(true);
        edits.current[getEditsClient()][id] = value;
    };
    var response = useGetSkillsCategoryQuery({ clientId: clientId, roleProfileId: roleProfileId });
    var invalidatingSkillUpdater = updateSkill({
        invalidates: [
            { queryKey: ["{platform}/leadership/api/v2/skill-categories"] },
        ],
    });
    var lightweightUpdater = updateSkill({});
    var _f = useState(null), dialog = _f[0], setDialog = _f[1];
    React.useEffect(function () {
        document.title = "Success Profiles";
    }, []);
    var originalData = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0];
    var readOnlyTabs = useMemo(function () { return ({
        variant: "readonly",
        tabs: [
            {
                label: "Functional Impact",
                hash: "functional",
                content: (_jsx(SuccessProfilesFunctionalImpact, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: UneditableProgressStepperFactories["functional"](makeEdit) })),
            },
            {
                label: "Organizational Impact",
                hash: "organizational",
                content: (_jsx(SuccessProfilesOrganizationalImpact, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: UneditableProgressStepperFactories["organizational"](makeEdit) })),
            },
            {
                label: "Capabilities",
                hash: "capabilities",
                content: (_jsx(SuccessProfilesCapabilities, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: UneditableProgressStepperFactories["capabilities"](makeEdit) })),
            },
            {
                label: "Potential",
                hash: "potential",
                content: (_jsx(SuccessProfilesPotential, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: UneditableProgressStepperFactories["potential"](makeEdit) })),
            },
        ],
    }); }, [originalData]);
    var editableTabs = useMemo(function () { return ({
        variant: "editable",
        tabs: [
            {
                label: "Functional Impact",
                hash: "functional",
                content: (_jsx(SuccessProfilesFunctionalImpact, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: EditableProgressStepperFactories["functional"](makeEdit) })),
            },
            {
                label: "Organizational Impact",
                hash: "organizational",
                content: (_jsx(SuccessProfilesOrganizationalImpact, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: EditableProgressStepperFactories["organizational"](makeEdit) })),
            },
            {
                label: "Capabilities",
                hash: "capabilities",
                content: (_jsx(SuccessProfilesCapabilities, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: EditableProgressStepperFactories["capabilities"](makeEdit) })),
            },
            {
                label: "Potential",
                hash: "potential",
                content: (_jsx(SuccessProfilesPotential, { clientId: clientId || "", roleProfileId: roleProfileId, progressStepperFactory: EditableProgressStepperFactories["potential"](makeEdit) })),
            },
        ],
    }); }, [originalData, revertKey]);
    var guidanceDialog = (_jsx(GuidanceDialog, { onClose: function () {
            setDialog(null);
        }, onClick: function () {
            setTabs(editableTabs);
            setDialog(null);
        } }));
    var exitDialog = (_jsx(ConfirmationDialog, { texts: exitDialogTexts, onClose: function () {
            setDialog(null);
        }, onClick: function () {
            Object.keys((edits === null || edits === void 0 ? void 0 : edits.current[getEditsClient()]) || []).forEach(function (key) {
                delete edits.current[getEditsClient()][key];
            });
            setHasEdits(false);
            setTabs(readOnlyTabs);
            setDialog(null);
        } }));
    var saveDialog = (_jsx(ConfirmationDialog, { texts: saveDialogTexts, onClose: function () {
            setDialog(null);
        }, onClick: function () {
            var _a;
            var entries = Object.entries(((_a = edits.current) === null || _a === void 0 ? void 0 : _a[getEditsClient()]) || {});
            edits.current[getEditsClient()] = {};
            entries.forEach(function (_a, index) {
                var id = _a[0], value = _a[1];
                if (index == entries.length - 1) {
                    invalidatingSkillUpdater.mutate({ id: id, benchmark: value });
                }
                else {
                    lightweightUpdater.mutate({ id: id, benchmark: value });
                }
            });
            setDialog(null);
        } }));
    var _g = useState({ variant: "readonly", tabs: readOnlyTabs.tabs }), _h = _g[0], tabs = _h.tabs, variant = _h.variant, setTabs = _g[1];
    if (!originalData) {
        return _jsx(SpinnerAndErrorer, { conditions: [response] });
    }
    var data = JSON.parse(JSON.stringify(originalData));
    data.skillGroups = originalData.skillGroups.map(function (skillGroup) { return (__assign(__assign({}, skillGroup), { skills: skillGroup.skills.map(function (skill) {
            var _a, _b;
            return (__assign(__assign({}, skill), { benchmark: ((_b = (_a = edits === null || edits === void 0 ? void 0 : edits.current) === null || _a === void 0 ? void 0 : _a[getEditsClient()]) === null || _b === void 0 ? void 0 : _b[skill.id]) || skill.benchmark }));
        }) })); });
    var _j = data.roleProfile, name = _j.name, lastUpdatedTs = _j.lastUpdatedTs;
    var DownloadMenu = function () {
        var params = useParams();
        var downloadQuery = useSuccessProfileDownloadReport({});
        var getDownloadLink = function (linkType) {
            downloadQuery.mutate({
                reportType: linkType == "CSV" ? "SPR_CSV" : "SPR",
                clientId: params.clientId ? params.clientId : "Master",
                roleProfileId: params.roleProfileId,
            }, {
                onSuccess: function (data) {
                    if (data.status == "200") {
                        var linkTag = document.createElement("a");
                        linkTag.href = data.download_link;
                        linkTag.target = "_blank";
                        linkTag.download = "success_profile_report";
                        linkTag.click();
                    }
                },
            });
        };
        var options = [
            {
                label: "Download PDF",
                onChange: function () {
                    getDownloadLink("PDF");
                },
            },
            {
                label: "Download CSV",
                onChange: function () {
                    getDownloadLink("CSV");
                },
            },
        ];
        return (_jsx(_Fragment, { children: _jsx(Dropdown, { options: options, label: "Download", buttonOverrideProps: {
                    variant: IntelligenceButtonVariant.Secondary,
                    loading: downloadQuery.isPending,
                } }) }));
    };
    var editButtons = [
        clientId ? (_jsx(OutlinedButton, { icon: IconName.mdiPencil, buttonText: "Edit mode", loading: false, onClick: function () {
                setDialog(guidanceDialog);
            } })) : (_jsx(_Fragment, {})),
        _jsx(DownloadMenu, {}),
    ];
    var changeButtons = [
        _jsx(Button, { text: "Save changes", onClick: function () {
                setDialog(saveDialog);
            }, disabled: !hasEdits }),
        _jsx(OutlinedButton, { icon: RevertIcon, buttonText: "Revert changes", disabled: !hasEdits, loading: false, onClick: function () {
                Object.keys((edits === null || edits === void 0 ? void 0 : edits.current) || {}).forEach(function (key) {
                    edits.current[key] = {};
                });
                setHasEdits(false);
                setTimeout(function () {
                    setRevertKey(Math.random());
                }, 100);
            } }),
        _jsx(OutlinedButton, { icon: IconName.mdiClose, buttonText: "Exit edit mode", loading: false, onClick: function () {
                setDialog(exitDialog);
            } }),
    ];
    return (_jsxs(_Fragment, { children: [dialog && createPortal(dialog, document.body), _jsx(SecondaryMainHeading, { secondaryHeadingTitle: _jsx(BannerTitle, { roleName: name, clientId: clientId, lastUpdatedTs: lastUpdatedTs }), backToButton: _jsx(BackToButton, { title: "Success Profiles", url: "".concat(getRoute("SuccessProfiles")).concat(!clientId ? "#heidrick" : "") }) }), _jsx("div", { children: _jsx(ControlledOverviewTabs, { "data-testid": "controlledOverviewTabs", downloadButton: variant == "editable" ? changeButtons : editButtons, tabs: __spreadArray([
                        {
                            label: "Overview",
                            hash: "overview",
                            content: (_jsx(SuccessProfileOverview, { clientId: clientId, roleProfileId: roleProfileId })),
                        }
                    ], tabs, true) }, revertKey) })] }));
};
export var SuccessProfileRole = function () {
    var edits = useRef({});
    var params = useParams();
    return hasPermissionExist("ORG_DASHBOARD_UI/SUCCESS_PROFILES") ? (_jsx(SuccessProfileRoleImplementation, __assign({ edits: edits }, params))) : (_jsx("div", { children: "You don't have success profiles setup." }));
};
