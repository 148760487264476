// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UibhX5nFUcQ5B6EaV13A {
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  background-color: var(--grey-color-10);
  border-radius: 24px;
  height: 78px;
  padding: 16px;
}

.xuiX6NhEM6GDWHFdTS96 {
  font-size: 14px;
}

.lgMU4LsKzGh8iDX7PLYX {
  font-family: Avenir Heavy;
}

.IPNzj5r8u5cNJWUpZ2ei {
  width: 35px;
  padding: 8px 16px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "Avenir Light", sans-serif;
}

.mvwFmGjiRcJGY0cHAECc {
  width: 100%;
}

.O0LWjBzXTQotbAwIbZxr {
  position: relative;
  top: -20px;
  display: flex;
  flex-basis: 1 1 1;
  width: 100%;
}

.O0LWjBzXTQotbAwIbZxr div {
  width: 100%;
  text-align: center;
}

._BORu4Ki6BUvL2t6mOaY {
  width: 100%;
  padding: 0 40px;
  position: relative;
  top: -4px;
}

.B0mrlZSF0ssZPAXIfujx {
  width: 60px;
  min-width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/EditableSlider/EditableSlider.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;EACA,sCAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uCAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF","sourcesContent":[".wholeContainer {\n  display: flex;\n  gap: 10px;\n  flex-direction: row;\n  width: 100%;\n  background-color: var(--grey-color-10);\n  border-radius: 24px;\n  height: 78px;\n  padding: 16px;\n}\n\n.label {\n  font-size: 14px;\n}\n\n.selectedLabel {\n  font-family: Avenir Heavy;\n}\n\n.calculated {\n  width: 35px;\n  padding: 8px 16px 0 0;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 20px;\n  font-family: \"Avenir Light\", sans-serif;\n}\n\n.sliderRegion {\n  width: 100%;\n}\n\n.sliderLabels {\n  position: relative;\n  top: -20px;\n  display: flex;\n  flex-basis: 1 1 1;\n  width: 100%;\n}\n\n.sliderLabels div {\n  width: 100%;\n  text-align: center;\n}\n\n.sliderContainer {\n  width: 100%;\n  padding: 0 40px;\n  position: relative;\n  top: -4px;\n}\n\n.textInputContainer {\n  width: 60px;\n  min-width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wholeContainer": `UibhX5nFUcQ5B6EaV13A`,
	"label": `xuiX6NhEM6GDWHFdTS96`,
	"selectedLabel": `lgMU4LsKzGh8iDX7PLYX`,
	"calculated": `IPNzj5r8u5cNJWUpZ2ei`,
	"sliderRegion": `mvwFmGjiRcJGY0cHAECc`,
	"sliderLabels": `O0LWjBzXTQotbAwIbZxr`,
	"sliderContainer": `_BORu4Ki6BUvL2t6mOaY`,
	"textInputContainer": `B0mrlZSF0ssZPAXIfujx`
};
export default ___CSS_LOADER_EXPORT___;
