var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var RedFlag = function () { return (_jsx("svg", __assign({ viewBox: "0 0 24 24", style: {
        height: "20px",
        width: "20px",
    } }, { children: _jsx("path", { d: "M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z", fill: "#c15151" }) }))); };
export default RedFlag;
