var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import styles from "./SuccessProfile.module.scss";
import Dialog from "../../Dialog";
var SuccessProfileLegend = function () {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: styles.successProfileLegend }, { children: [_jsx("div", { children: "Undeveloped (1.00 - 1.99)" }), _jsx("div", { children: "Emerging (2.00 - 2.99)" }), _jsx("div", { children: "Proficient (3.00 - 3.99)" }), _jsx("div", { children: "Advanced (4.00 - 4.99)" }), _jsx("div", { children: "Expert (5.00)" })] })) }));
};
var SkillGroupHeading = function (props) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleClose = function () {
        setIsOpen(false);
    };
    var EditGuidancePopupBody = function () {
        return (_jsx("div", { children: "The experience levels targets have already been validated during a rigorous process knowledge. You may adjust them if you are looking for someone with more or less experience in a given area. Please note that in general, more senior leaders should have higher experience levels needed than the leaders below them, so you should aim to set experience levels needed that increase across leadership levels." }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.skillGroupLayout }, { children: [_jsx("div", __assign({ className: styles.skillGroupTitle }, { children: props.title })), _jsxs("div", __assign({ className: styles.successProfileLegendAndTitleContainer }, { children: [_jsx("div", { children: _jsx(SuccessProfileLegend, {}) }), _jsxs("div", __assign({ className: styles.targetHeadingLayout }, { children: [_jsx("div", { className: styles.targetRectangle }), _jsx("div", __assign({ className: styles.targetHeading }, { children: "Target" }))] }))] }))] })), _jsxs("div", __assign({ className: styles.skillGroupDescription }, { children: [props.description, _jsxs("span", { children: [" ", "Looking to edit any targets?", " ", _jsx("button", __assign({ onClick: function () { return setIsOpen(true); }, className: styles.readBeforeEditingButton }, { children: "Read this before editing" }))] }), _jsx(Dialog, { header: "Guidance for how to edit target experience levels", body: _jsx(EditGuidancePopupBody, {}), visible: isOpen, buttons: {
                            okButtonProps: {
                                text: "Got it",
                                onClick: handleClose,
                            },
                        }, onClose: handleClose })] }))] }));
};
export default SkillGroupHeading;
