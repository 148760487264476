var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuItem, MenuList, styled } from "@mui/material";
import { useNavigate } from "../../hooks/navigate";
import { useOktaAuth } from "../../hooks/oktaAuth";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/system";
import React from "react";
import { useTheme } from "@mui/system";
import Triangle from "./Triangle";
import { useGetClientPlatformQuery, prepareEightfoldDomain, useGetPermissions, } from "../../store/api/core";
import { hasPermissionExist } from "../../store/api/permissions";
import SpinnerAndErrorer, { willHold, } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import { haveLegacyTabs, haveSuccessProfilePages, } from "../../common/featureFlags";
import { getRoute } from "../../routes/getAllRoutes";
import styles from "./Header.module.scss";
import { useClient } from "../../hooks/client";
var Header = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var clientId = useClient().clientId;
    var location = window.location.pathname;
    var navigate = useNavigate();
    var theme = useTheme();
    var oktaContext = useOktaAuth();
    var permissions = useGetPermissions();
    var platformClients = useGetClientPlatformQuery({});
    var isOrgDashboardUIPermission = hasPermissionExist("ORG_DASHBOARD_UI");
    var successProfileApplicationPermission = hasPermissionExist("SUCCESS_PROFILES_UI");
    var isSuccessProfileUIPermission = hasPermissionExist("ORG_DASHBOARD_UI/SUCCESS_PROFILES");
    var _h = React.useState(null), anchorEl = _h[0], setAnchorEl = _h[1];
    var oktaTokenStorage = JSON.parse(localStorage.getItem("okta-token-storage") || "{}");
    var userName = ((_b = (_a = oktaTokenStorage === null || oktaTokenStorage === void 0 ? void 0 : oktaTokenStorage.idToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.name) || undefined;
    var isClientNavigatorEnabled = (_g = (_f = (_e = (_d = (_c = platformClients === null || platformClients === void 0 ? void 0 : platformClients.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.applications) === null || _f === void 0 ? void 0 : _f.navigator) === null || _g === void 0 ? void 0 : _g.isEnabled;
    var isSuccessProfilePermission = successProfileApplicationPermission && isSuccessProfileUIPermission;
    if (permissions && permissions.length > 0) {
        if (isOrgDashboardUIPermission === false ||
            isClientNavigatorEnabled === false) {
            var targetRoute = isSuccessProfilePermission
                ? "SuccessProfiles"
                : "PermissionsNotFound";
            if (!location.includes(getRoute(targetRoute))) {
                navigate(getRoute(targetRoute));
            }
        }
    }
    var onNavigationClick = function (url) {
        if (url === "signOut") {
            setAnchorEl(null);
            oktaContext.oktaAuth.signOut();
        }
        else {
            if (url.startsWith("/")) {
                url = "".concat(url, "?client=").concat(clientId);
            }
            window.location.href = url;
        }
    };
    var Links = function () {
        var _a, _b, _c, _d, _e, _f;
        if (!clientId ||
            !oktaContext.authState ||
            !((_a = oktaContext.authState) === null || _a === void 0 ? void 0 : _a.isAuthenticated)) {
            // If not authenticated, don't try to load navigation
            return _jsx(Box, { sx: { width: "100%" } });
        }
        var eightfoldDomain = prepareEightfoldDomain((_f = (_e = (_d = (_c = (_b = platformClients === null || platformClients === void 0 ? void 0 : platformClients.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.applications) === null || _e === void 0 ? void 0 : _e.navigator) === null || _f === void 0 ? void 0 : _f.url);
        var headerEnabled = haveLegacyTabs();
        var successProfileBeta = {
            url: "".concat(getRoute("SuccessProfiles")),
            itemName: "Success Profiles (beta)",
        };
        var mainNavigation = headerEnabled
            ? [
                isOrgDashboardUIPermission
                    ? {
                        url: getRoute("Dashboard"),
                        itemName: "Intelligence",
                    }
                    : null,
                {
                    url: "".concat(eightfoldDomain, "/careerhub/team-planning/succession"),
                    itemName: "Leader Management",
                },
                hasPermissionExist("ORG_DASHBOARD_UI/REPORTS")
                    ? {
                        url: "".concat(eightfoldDomain, "/talent_management_analytics/dashboard?key=succession_plan_hs_v2"),
                        itemName: "Reports",
                    }
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/SCENARIO_PLANNING")
                    ? {
                        url: "".concat(eightfoldDomain, "/roles/my-roles?query_id=WNGLpkJnO"),
                        itemName: "Roles",
                    }
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/USERS")
                    ? {
                        url: "".concat(eightfoldDomain, "/careerhub/search/people"),
                        itemName: "Users",
                    }
                    : null,
            ].filter(function (value) { return value != null; })
            : [
                isOrgDashboardUIPermission
                    ? {
                        url: getRoute("Dashboard"),
                        itemName: "Intelligence",
                    }
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/LEADERSHIP_MANAGEMENT")
                    ? {
                        url: "".concat(eightfoldDomain, "/careerhub/team-planning/succession"),
                        itemName: "Leadership Management",
                    }
                    : null,
                isSuccessProfilePermission && haveSuccessProfilePages()
                    ? successProfileBeta
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/SCENARIO_PLANNING")
                    ? {
                        url: "".concat(eightfoldDomain, "/roles/talent-planning"),
                        itemName: "Scenario Planning (beta)",
                    }
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/REPORTS")
                    ? {
                        url: "".concat(eightfoldDomain, "/talent_management_analytics/dashboard?key=succession_plan_hs_v2"),
                        itemName: "Reports",
                    }
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/SUCCESS_PROFILES")
                    ? {
                        url: "".concat(eightfoldDomain, "/roles/my-roles"),
                        itemName: "Success Profiles",
                    }
                    : null,
                hasPermissionExist("ORG_DASHBOARD_UI/USERS")
                    ? {
                        url: "".concat(eightfoldDomain, "/careerhub/search/people"),
                        itemName: "Users",
                    }
                    : null,
            ].filter(function (value) { return value != null; });
        mainNavigation = isClientNavigatorEnabled
            ? mainNavigation
            : isSuccessProfilePermission
                ? [successProfileBeta]
                : [];
        var successProfilesIndex = mainNavigation.findIndex(function (_a) {
            var url = _a.url;
            return url === null || url === void 0 ? void 0 : url.includes(getRoute("SuccessProfiles"));
        });
        var underlinedIndex = location.includes(getRoute("SuccessProfiles"))
            ? successProfilesIndex
            : 0;
        var NavigationItem = function (props) { return (_jsx(MenuItem, __assign({ onClick: function () { return onNavigationClick(props.url); }, sx: {
                font: "16px/23px Avenir Medium",
                fontWeight: 500,
                paddingTop: "30px",
                height: "25px",
                paddingBottom: "33px",
                borderBottom: props.index === underlinedIndex
                    ? "3px solid #146DA6"
                    : "3px solid ${theme.palette.primary.light}",
                color: props.index === underlinedIndex
                    ? theme.palette.blue.main
                    : theme.palette.primary.dark,
                ":hover": {
                    color: theme.palette.blue.main,
                    backgroundColor: "inherit",
                },
            } }, { children: props.itemName }), props.index)); };
        if (willHold([platformClients])) {
            return (_jsx(Box, __assign({ sx: { width: "100%" } }, { children: _jsx(SpinnerAndErrorer, { conditions: [platformClients], errorDescription: "Unable to load navigation.", sx: {
                        position: "unset",
                        alignSelf: "center",
                        marginLeft: "30px",
                    } }) })));
        }
        var ProfileMenuItem = function () {
            return (_jsx(MenuItem, __assign({ className: styles.menuItem, onClick: function () {
                    window.location.href = "".concat(eightfoldDomain, "/careerhub/profile/");
                } }, { children: "My Profile" })));
        };
        var handleLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        closeAccountMenu();
                        return [4 /*yield*/, oktaContext.oktaAuth.signOut({})];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return (_jsxs(Box, __assign({ sx: {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            } }, { children: [_jsx(MenuList, { children: mainNavigation.map(function (_a, index) {
                        var itemName = _a.itemName, url = _a.url;
                        return (_jsx(NavigationItem, { itemName: itemName, url: url, index: index }, index));
                    }) }), _jsxs(MenuList, { children: [_jsxs(MenuItem, __assign({ onClick: onMenuClick, sx: {
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                cursor: "pointer",
                                ":hover": {
                                    backgroundColor: "white",
                                },
                            } }, { children: [_jsx(UserName, { name: userName }), _jsx(Box, __assign({ sx: { marginLeft: "10px" } }, { children: _jsx(Triangle, {}) }))] })), _jsxs(StyledMenu, __assign({ disableScrollLock: true, keepMounted: false, open: !!anchorEl, anchorEl: anchorEl, sx: {
                                left: "calc(100vw - 390px)",
                                top: "60px",
                            }, onClose: closeAccountMenu, PaperProps: {
                                style: {
                                    minWidth: "none",
                                    maxWidth: "none",
                                    width: "311px",
                                    minHeight: "none",
                                    maxHeight: "none",
                                    boxShadow: "none",
                                    borderRadius: "25px",
                                    border: "2px solid rgba(0, 33, 54, 0.2)",
                                },
                            } }, { children: [isClientNavigatorEnabled ? _jsx(ProfileMenuItem, {}) : null, _jsx(MenuItem, __assign({ className: styles.menuItem, onClick: handleLogout }, { children: "Logout" })), _jsx(StyledDivider, {}), isClientNavigatorEnabled ? (_jsx(MenuItem, __assign({ className: styles.menuItem, onClick: function () {
                                        return (window.location.href = "".concat(eightfoldDomain, "/careerhub/settings"));
                                    } }, { children: "Settings" }))) : null] }))] })] })));
    };
    var StyledLinks = styled(Links)({});
    var StyledAppBar = styled(AppBar)(function () { return ({
        "& .MuiList-root": {
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "0px",
        },
    }); });
    var StyledMenu = styled(Menu)(function () { return ({
        "& .MuiList-padding": {
            padding: "0",
        },
    }); });
    var StyledDivider = styled(Box)(function () { return ({
        width: "302px",
        height: "1px",
        margin: "0 6px",
        backgroundColor: "rgba(0, 33, 54, 0.2)",
    }); });
    var onMenuClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var closeAccountMenu = function () {
        setAnchorEl(null);
    };
    var UserName = function (_a) {
        var _b, _c, _d, _e;
        var name = _a.name;
        var width = "40px";
        var words = (name === null || name === void 0 ? void 0 : name.split(/\s+/g)) || [];
        var firstLetter = (_c = (_b = words[0]) === null || _b === void 0 ? void 0 : _b.slice(0, 1)) === null || _c === void 0 ? void 0 : _c.toUpperCase();
        var lastLetter = (_e = (_d = words[words.length - 1]) === null || _d === void 0 ? void 0 : _d.slice(0, 1)) === null || _e === void 0 ? void 0 : _e.toUpperCase();
        if (!firstLetter) {
            return null;
        }
        var Letters = function () {
            return (_jsx(Box, __assign({ component: "span", sx: {
                    width: width,
                    color: "white",
                    display: "inline-flex",
                    justifyContent: "center",
                    position: "relative",
                    top: "2px",
                } }, { children: _jsx(Box, __assign({ component: "span" }, { children: words.length > 1 ? "".concat(firstLetter).concat(lastLetter) : firstLetter })) })));
        };
        return (_jsx(Box, __assign({ sx: {
                height: width,
                width: width,
                backgroundColor: theme.palette.grey.A700,
                borderRadius: "50%",
                display: "inline-flex",
                flexDirection: "column",
                justifyContent: "center",
            } }, { children: _jsx(Letters, {}) })));
    };
    var LoaderContainer = styled(Box)({
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        left: "calc(50% - 150px)",
        top: "50%",
    });
    if (!permissions) {
        return (_jsxs(LoaderContainer, { children: [" ", _jsx(SpinnerAndErrorer, { conditions: { spin: function () { return false; }, error: function () { return false; } } }), " "] }));
    }
    if (permissions && (permissions === null || permissions === void 0 ? void 0 : permissions.length) > 0) {
        return (_jsxs(Box, __assign({ className: styles.headerContainer, "data-testid": "Header" }, { children: [_jsx(Box, { className: styles.headerBackground }), _jsx(StyledAppBar, __assign({ className: styles.tabBar }, { children: _jsxs(Box, __assign({ className: styles.logoContainer }, { children: [_jsx("img", { className: styles.logo, src: getRoute("Logo") }), _jsx(StyledLinks, {})] })) }))] })));
    }
    else {
        if (!location.includes(getRoute("PermissionsNotFound"))) {
            navigate(getRoute("PermissionsNotFound"));
        }
    }
};
export default Header;
