import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSearchParams } from "../../hooks/searchParams";
import { useGetPermissions, useGetClientListPlatformQuery, } from "../../store/api/core";
import { PermissionResource, PermissionResourceType, } from "../../store/api/permissions";
import { Box, styled } from "@mui/material";
import SpinnerAndErrorer from "../../components/SpinnerAndErrorer/SpinnerAndErrorer";
import PageNotFound from "../../components/PageNotFound";
var ClientIdEndpointCaller = function () {
    var _a, _b, _c, _d, _e, _f;
    var clientId = "";
    var permissions = useGetPermissions();
    var referrer = localStorage.getItem("referrer");
    var clientDetails = useGetClientListPlatformQuery({ navigatorUrl: referrer }, { shouldSkipCall: function () { return !referrer; } });
    if (!referrer) {
        return _jsx("div", { children: "Need referrer or client ID" });
    }
    var LoaderContainer = styled(Box)({
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        left: "calc(50% - 150px)",
        top: "50%",
    });
    if (!permissions ||
        clientDetails.isLoading ||
        !(((_a = clientDetails.data) === null || _a === void 0 ? void 0 : _a.data) && ((_b = clientDetails.data) === null || _b === void 0 ? void 0 : _b.data.length) > 0)) {
        return (_jsxs(LoaderContainer, { children: [" ", _jsx(SpinnerAndErrorer, { conditions: { spin: function () { return false; }, error: function () { return false; } } }), " "] }));
    }
    for (var _i = 0, permissions_1 = permissions; _i < permissions_1.length; _i++) {
        var permission = permissions_1[_i];
        if (permission.resourceName === PermissionResource.ORG_DASHBOARD_UI &&
            permission.resourceType === PermissionResourceType.Application &&
            ((_c = permission.clientIds) === null || _c === void 0 ? void 0 : _c.length) === 1 &&
            permission.clientIds[0] !== "*") {
            clientId = permission.clientIds[0];
        }
    }
    if (!clientId) {
        if (((_d = clientDetails.data) === null || _d === void 0 ? void 0 : _d.data) && ((_e = clientDetails.data) === null || _e === void 0 ? void 0 : _e.data.length) === 1) {
            clientId = (_f = clientDetails.data) === null || _f === void 0 ? void 0 : _f.data[0].id;
        }
    }
    if (clientId) {
        localStorage.setItem("clientId", clientId);
        localStorage.removeItem("referrer");
    }
    else {
        // If we still don't have a client ID, we need to show an error and send the user
        // Admin Portal
        if (process.env.REACT_APP_ADMIN_PORTAL_URL) {
            window.location.href = "".concat(process.env.REACT_APP_ADMIN_PORTAL_URL);
        }
        return (_jsx(PageNotFound, { title: "Client not found", description: "Please contact your administrator to ensure you have access to the application." }));
    }
    return _jsx(Outlet, {});
};
export var RequiredClientId = function () {
    var searchParams = useSearchParams()[0];
    var params = useParams();
    var clientId = searchParams.get("client") || params.clientId;
    if (clientId) {
        localStorage.setItem("clientId", clientId);
    }
    else if (!localStorage.getItem("clientId")) {
        return _jsx(ClientIdEndpointCaller, {});
    }
    return _jsx(Outlet, {});
};
