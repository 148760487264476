// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ABIm8b7Kez1t5PGEvBJk {
  color: var(--act-dark);
  border-color: var(--act-dark) !important;
}

.T1dwId_7PEV_grWVJQq_ {
  margin-bottom: 6px;
}

.tPpwPbYG6Xcm9BHHBxze {
  position: absolute;
  bottom: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/TalentReview/TalentReview.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,wCAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;AACF","sourcesContent":[".formError {\n  color: var(--act-dark);\n  border-color: var(--act-dark) !important;\n}\n\n.createNineboxNameBox {\n  margin-bottom: 6px;\n}\n\n.resetButtonWrapper {\n  position: absolute;\n  bottom: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formError": `ABIm8b7Kez1t5PGEvBJk`,
	"createNineboxNameBox": `T1dwId_7PEV_grWVJQq_`,
	"resetButtonWrapper": `tPpwPbYG6Xcm9BHHBxze`
};
export default ___CSS_LOADER_EXPORT___;
