// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R4UYgoVSuSZOc5Ij6lDd {
  padding: 5px 0;
}

.C7ftPfTcNqhmE303e7dw {
  font-size: 16px;
  font-family: "Avenir Medium", sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.iLhee11jbe3sgJkcv3WU {
  font-size: 14px;
  color: #1a212e;
}`, "",{"version":3,"sources":["webpack://./src/components/Autocomplete/Autocomplete.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;EACA,wCAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF","sourcesContent":[".autocompleteMainContainer {\n  padding: 5px 0;\n}\n\n.autocompleteLabel {\n  font-size: 16px;\n  font-family: \"Avenir Medium\", sans-serif;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.closeOutlined {\n  font-size: 14px;\n  color: #1a212e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocompleteMainContainer": `R4UYgoVSuSZOc5Ij6lDd`,
	"autocompleteLabel": `C7ftPfTcNqhmE303e7dw`,
	"closeOutlined": `iLhee11jbe3sgJkcv3WU`
};
export default ___CSS_LOADER_EXPORT___;
