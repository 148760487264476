import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "../hooks/oktaAuth";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
var ErrorOnNoRedirect = function () {
    var _a = useState(_jsx(Box, {})), page = _a[0], setPage = _a[1];
    useEffect(function () {
        setTimeout(function () {
            setPage(_jsx("div", { children: "Error: can not deduce auth state from Okta" }));
        }, 5000);
    });
    return page;
};
export var RequiredAuth = function () {
    var _a = useOktaAuth(), oktaAuth = _a.oktaAuth, authState = _a.authState;
    if (!authState || !(authState === null || authState === void 0 ? void 0 : authState.isAuthenticated)) {
        // Capture referrer before redirecting to login
        var referrerHostname = document.referrer
            ? new URL(document.referrer).hostname
            : "";
        var hostname = window.location.hostname;
        if (referrerHostname && referrerHostname !== hostname) {
            localStorage.setItem("referrer", referrerHostname);
            // Clear clientId if user is not authenticated and the referrer is different
            localStorage.removeItem("clientId");
        }
        var originalUri = toRelativeUrl(window.location.href, window.location.origin);
        if (authState) {
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
        else {
            return _jsx("div", { children: "Can not deduce Auth State from Okta" });
        }
        return _jsx(ErrorOnNoRedirect, {});
    }
    return _jsx(Outlet, {});
};
